import { Component, inject, OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { FavoriteService } from '@modules/favorites/services/favorite.service';
import { Analysis } from 'src/app/api/vat';
import { filter, Subscription } from 'rxjs';
import { FavoriteDetail } from 'src/app/global-models';
import { OAuthService } from 'angular-oauth2-oidc';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  farStar = farStar;
  fasStar = fasStar;
  favoriteAnalysis: Analysis[];
  favoriteDetail: FavoriteDetail[];
  subscriptions: Subscription[] = [];
  mySubscription: Subscription;

  constructor(
    library: FaIconLibrary,
    private favoriteService: FavoriteService,
    private oauth: OidcSecurityService
  ) {
    // Add multiple icons to the library
    library.addIcons(fasStar, farStar);

    this.oauth.getAccessToken().subscribe((accessToken) => {
      console.log("access token: ", accessToken);
    });

    this.oauth.getAuthenticationResult().subscribe((accessToken) => {
      console.log("getAuthenticationResult: ", accessToken);
    });

    this.oauth.getConfiguration().subscribe((configuration) => {
      console.log("configuration", configuration);

      var cfg = configuration.configId.split("0-")[1];
      // this.oauth.forceRefreshSession({}, configuration.configId).subscribe((accessToken) => {
      //   console.log("forceRefreshSession: ", accessToken);
      // });
    });

  }

  ngOnInit() {
    this.subscriptions.push(
      this.favoriteService.favoriteAnalysis.subscribe((res: Analysis[]) => {
        this.favoriteAnalysis = res;
      })
    );

    // .pipe(filter((notification) => notification.type === EventTypes.CheckSessionReceived))
    // .subscribe((value) => console.log('CheckSessionChanged with value', value));
  }
}
