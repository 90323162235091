import { Injectable } from '@angular/core';
import { InternalCurrentCustomerService } from '@modules/shell/services/internal-current-customer.service';
import { Message, MessageService } from 'src/app/api/vat';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  private MessageList: BehaviorSubject<Message[]> = new BehaviorSubject<
    Message[]
  >([]);
  private SelectedMessage: Subject<number> = new Subject<number>();
  private NewMessagesCount: Subject<number> = new Subject<number>();

  public MessageList$: Observable<Message[]> = this.MessageList.asObservable();
  public SelectedMessage$: Observable<number> =
    this.SelectedMessage.asObservable();
  public NewMessagesCount$: Observable<number> =
    this.NewMessagesCount.asObservable();

  constructor(
    private messageService: MessageService,
    private internalCurrentCustomerService: InternalCurrentCustomerService
  ) {}

  announceMessages() {
    const customerid: number =
      +this.internalCurrentCustomerService.getCustomerId();
    this.messageService
      .getAllMessages(customerid)
      .subscribe((result) => {
        this.MessageList.next(result);
      });
  }

  setSelectedMessage(postingNoteId: number) {
    this.SelectedMessage.next(postingNoteId);
  }

  setNewMessagesCount(newCount: number) {
    this.NewMessagesCount.next(newCount);
  }

  setMessageAsRead(item: Message) {
    this.messageService
      .markAsRead(item.postingNoteId, item.isNews, item.isGlobal)
      .subscribe((result) => this.announceMessages());
  }

  setMessageAsUnRead(item: Message) {
    this.messageService
      .markAsUnRead(item.postingNoteId, item.isNews, item.isGlobal)
      .subscribe((result) => this.announceMessages());
  }

  checkForNewMessages() {
    const customerid: number =
      +this.internalCurrentCustomerService.getCustomerId();
    this.messageService
      .getAllMessages(customerid)
      .subscribe((result) => {
        this.MessageList.next(result);
      });
  }
}
